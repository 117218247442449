// SD Connect specific text overrides
let lang_overrides = {
  en: {
    message: {
      keys: { 
        default: { 
          project: "5e26ea3590b94423aea712aad7a289d4", 
          container: "sd-public-keys", 
          object: "sd-pubkey-c4gh.pub",
        },
      },
      index: {
        formName: "CSC Account",
        loginmethods: [
          {
            msg: "Log In with Haka",
            href: "/login/oidc_front",
          },
          {
            msg: "Log in with CSC Login",
            href: "/loginpassword",
          },
        ],
      },
      program_name: "SD Connect",
      program_description: "SD Connect provides a simple-to-use web user " +
        "interface, along with a command line interface, for storing " +
        "encrypted sensitive data for the duration of your research project " +
        "by allowing data uploads through drag-n-drop, and simple data " +
        "sharing.",
      helplink: "https://docs.csc.fi/data/sensitive-data/",
      dashboard: {
        default_notify: "The information on consumed billing units and " +
                        "available quota is derived from the default CSC " +
                        "values. Default quota can be increased, and the " +
                        "increase will not reflect here.",
        links: [
          {
            msg: "Sensitive Data Services User Guide",
            href: "https://docs.csc.fi/data/sensitive-data/",
          },
          {
            msg: "Billing Unit Calculator",
            href: "https://research.csc.fi/pricing#buc",
          },
          {
            msg: "About Sensitive Data Services for Research",
            href: "https://research.csc.fi/sensitive-data",
          },
        ],
      },
      encrypt: {
        defaultKeysMessage: "By default SD Connect adds a public key to the " +
                            "file encrypt to make the uploaded files " +
                            "available to other Sensitive Data services.",
      },
    },
  },
  fi: {
    message: {
      keys: { 
        default: { 
          project: "5e26ea3590b94423aea712aad7a289d4", 
          container: "sd-public-keys", 
          object: "sd-pubkey-c4gh.pub",
        },
      },
      formName: "CSC Käyttäjä",
      index: {
        loginmethods: [
          {
            msg: "Kirjaudu Haka:lla",
            href: "/login",
          },
          {
            msg: "Kirjaudu CSC käyttäjällä",
            href: "/loginpassword",
          },
        ],
      },
      program_name: "SD Connect",
      program_description: "SD Connect tarjoaa yksinkertaisen " +
        "web-käyttöliittymän ja komentorivikäyttöliittymän sensitiivisen " +
        "datan säilyttämiseen tutkimusprojektin ajaksi, mahdollistamalla " +
        "tiedostojen lähettämisen raahaamalla ja yksinkertaisen jakamisen.",
      helplink: "https://docs.csc.fi/data/sensitive-data/",
      dashboard: {
        default_notify: "Tieto laskutusyksiköiden kulutuksesta ja " +
                        "käyttörajasta on laskettu CSC:n Allas-palvelun " +
                        "oletusarvojen mukaan. Mahdollinen korotettu " +
                        "käyttöraja ei näy käyttöliittymässä.",
        links: [
          {
            msg: "Sensitive Data Services User Guide",
            href: "https://docs.csc.fi/data/sensitive-data/",
          },
          {
            msg: "Billing Unit Calculator",
            href: "https://research.csc.fi/pricing#buc",
          },
          {
            msg: "About Sensitive Data Services for Research",
            href: "https://research.csc.fi/sensitive-data",
          },
        ],
      },
      encrypt: {
        defaultKeysMessage: "SD Connect lisää oletuksena salaukseen julkisen " +
                            "avaimen, jolla lähetetyt tiedostot ovat " +
                            "käytettävissä myös muissa arkaluontoisen datan " + 
                            "palveluissa.",
      },
    },
  },
};

export default lang_overrides;

